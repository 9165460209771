import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { TrackButton } from 'components/TrackButton';
import { TrackingEvent } from 'lib/tracking/events';

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} 0`,
}));
const Center = styled('div')({
  textAlign: 'center',
});

const Button = styled(TrackButton)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(9)}`,
  height: '48px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}));

const FAQItem = ({
  question,
  answer,
}: {
  question: string | undefined;
  answer: string | undefined;
}) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
      <Typography color="primary" fontWeight={500}>
        {question}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>{answer}</Typography>
    </AccordionDetails>
  </Accordion>
);

export type FaqProps = any;
export type FaqEntry = any;

export default function FAQ(data: FaqProps) {
  const showButton = data?.buttonText && data?.buttonUrl;

  return (
    <Container maxWidth="md">
      <Box my={12}>
        <Box mb={6}>
          <Typography variant="h2">{data?.headline}</Typography>
        </Box>
        <Box mb={4}>
          {data.faqEntries.map((entry: any, key: any) => {
            const { question, answer } = entry.fields as any;
            return <FAQItem key={key} question={question} answer={answer} />;
          })}
        </Box>
        <Center>
          {showButton && (
            <Link href={data?.buttonUrl}>
              <Button
                variant="contained"
                trackingEvent={TrackingEvent.FAQ_BUTTON_ONINTERACT}
              >
                {data?.buttonText}
              </Button>
            </Link>
          )}
        </Center>
      </Box>
    </Container>
  );
}
