import { Box } from '@mui/material';
import { TealFooterUnified } from '@finanzcheck/teal-components';

interface FooterProps {
  datenschutzUrl?: string;
}

const footerLinks = {
  datenschutz: '/datenschutz',
  agb: '/agb',
  impressum: '/impressum',
};

export const Footer: React.FC<FooterProps> = ({ datenschutzUrl }) => {
  return (
    <Box
      mt={(theme) => theme.spacing(3)}
      borderTop={(theme) => `1px solid ${theme.palette.grey[300]}`}
    >
      <TealFooterUnified
        origin="smava"
        oneTrustHandler={() => window?.OneTrust?.ToggleInfoDisplay()}
        footerLinks={{
          ...footerLinks,
          ...(datenschutzUrl && { datenschutz: datenschutzUrl }),
        }}
      />
    </Box>
  );
};
