// @ts-nocheck
// Needed due to the data structure of the FAQ import.
import { IFaqEntry } from 'types/generated/contentful';

export const faqEntries: [IFaqEntry] = [
  {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'vh9g3jzzo9qx',
        },
      },
      id: '2nDBkjW6askCiRiVFvtKOj',
      type: 'Entry',
      createdAt: '2022-01-04T11:56:37.700Z',
      updatedAt: '2022-01-04T11:56:37.700Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 1,
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'faqEntry',
        },
      },
      locale: 'de-DE',
    },
    fields: {
      name: 'What do I have to do, to take on a loan?',
      question: 'Was muss ich tun, um einen Kredit aufzunehmen?',
      answer:
        'Füllen Sie die Online-Kreditanfrage aus, entscheiden Sie sich für das passende Angebot und reichen Sie alle geforderten Unterlagen ein.',
    },
  },
  {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'vh9g3jzzo9qx',
        },
      },
      id: '4QVZ4MIhuK07tqum16niXI',
      type: 'Entry',
      createdAt: '2022-01-04T11:58:52.615Z',
      updatedAt: '2022-01-04T11:58:52.615Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 1,
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'faqEntry',
        },
      },
      locale: 'de-DE',
    },
    fields: {
      name: 'How long does it take until my request will be processed?',
      question: 'Wie lange dauert es, bis meine Kreditanfrage bearbeitet wird?',
      answer:
        'Maximal 48 Stunden. Innerhalb kürzester Zeit erhalten Sie Ihre persönlichen Kreditangebote. Die Prüfung Ihrer Unterlagen bei Kreditantrag dauert lediglich wenige Werktage.',
    },
  },
  {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'vh9g3jzzo9qx',
        },
      },
      id: '1VTeq6BSeq5qUIgXDwESW1',
      type: 'Entry',
      createdAt: '2022-01-04T11:59:39.143Z',
      updatedAt: '2022-01-04T11:59:39.143Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 1,
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'faqEntry',
        },
      },
      locale: 'de-DE',
    },
    fields: {
      name: 'How much does a loan request cost?',
      question: 'Was kostet die Kreditanfrage mit smava?',
      answer: 'Die Kreditanfrage mit smava ist kostenlos.',
    },
  },
  {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'vh9g3jzzo9qx',
        },
      },
      id: '1wwj9kIcJWzjIx8xPj5fgS',
      type: 'Entry',
      createdAt: '2022-01-04T12:00:24.518Z',
      updatedAt: '2022-01-04T12:00:24.518Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 1,
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'faqEntry',
        },
      },
      locale: 'de-DE',
    },
    fields: {
      name: 'Which documents are needed?',
      question: 'Welche Unterlagen muss ich für einen Kreditantrag einreichen?',
      answer:
        'In der Regel wird nach Kontoauszügen, Gehaltsnachweisen, Selbstauskunft und einer Kopie Ihres Personalausweises gefragt. Sollten weitere Dokumente verlangt werden, ist dies im jeweiligen Kreditangebot vermerkt.',
    },
  },
];
