import { Box, Container, Typography, Paper, styled } from '@mui/material';

import { RichText } from 'components/ContentfulContent/RichText/RichText';

import { LegalTextGoogleFooter } from './Stage/LegalText';

const ColoredBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  overflow: 'hidden',
}));

const GridContainer = styled(Container)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(333px, 1fr))',
  gap: '16px',
  [theme.breakpoints.up('md')]: {
    gridGap: '24px',
    '& a': {
      fontWeight: 500,
      '&:hover': {
        color: 'rgb(117, 196, 127)',
        textDecoration: 'none',
      },
    },
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  '& p': {
    fontWeight: 500,
    marginBottom: 0,
  },

  '& a': {
    display: 'block',
    cursor: 'pointer',
    padding: `${theme.spacing(0.5)} 0`,
    textDecoration: 'none',
    '&:before': {
      content: '""',
      display: 'inline-block',
      height: '0.555em',
      width: '0.555em',
      cursor: 'pointer',
      transform: 'rotate(-45deg)',
      marginRight: theme.spacing(2),
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: '0px 0.111em 0.111em 0px',
      borderImage: 'none 100% / 1 / 0 stretch',
      marginBottom: '0.111em',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export type LinkListProps = any;
export default function SEOLinks(data?: LinkListProps) {
  return (
    <ColoredBackground>
      <Box my={9}>
        <GridContainer>
          <div>
            <Typography variant="h2" mb={2}>
              {data?.headline}
            </Typography>
            <RichText document={data?.linkBox1}></RichText>
          </div>
          <Item elevation={0}>
            <RichText document={data?.linkBox2}></RichText>
          </Item>
          <Item elevation={0}>
            <RichText document={data?.linkBox3}></RichText>
          </Item>
          <Item elevation={0}>
            <RichText document={data?.linkBox4}></RichText>
          </Item>
          <Item elevation={0}>
            <RichText document={data?.linkBox5}></RichText>
          </Item>
          <Item elevation={0}>
            <RichText document={data?.linkBox6}></RichText>
          </Item>
        </GridContainer>
        <br />
        <LegalTextGoogleFooter showRadioText/>
      </Box>
    </ColoredBackground>
  );
}
