/**
 * Renders the StickyBanner component.
 * It's a component used by Variation B of the App Redirection Experiment CAT-3151.
 *
 * @component
 * @example
 * return (
 *   <StickyBanner />
 * )
 */
import React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { InstallMobileOutlined } from '@mui/icons-material';
import { ButtonName, redirectToCorrectStore } from './helpers';

const StickyBanner: React.FC = () => (
  <Box
    display={{ xs: 'flex', md: 'none' }}
    position="sticky"
    top={0}
    left={0}
    right={0}
    justifyContent="space-between"
    alignItems="center"
    py={(theme) => theme.spacing(1.5)}
    px={(theme) => theme.spacing(2)}
    gap={(theme) => theme.spacing(1)}
    bgcolor={(theme) => theme.palette.grey[800]}
    zIndex={1}
  >
    <Box display="flex" flexDirection="row" gap={(theme) => theme.spacing(1)}>
      <InstallMobileOutlined
        sx={{ color: (theme) => theme.palette.common.white }}
      />
      <Box>
        <Typography
          variant="body2"
          color={(theme) => theme.palette.common.white}
          fontWeight="bold"
        >
          Nicht verpassen!
        </Typography>
        <Typography
          variant="body2"
          color={(theme) => theme.palette.common.white}
        >
          Die neue smava-App ist da.
        </Typography>
      </Box>
    </Box>
    <Link
      onClick={() =>
        redirectToCorrectStore(ButtonName.BUTTON_TOP_STICKY_BANNER)
      }
    >
      <Button
        data-test-id="CTA"
        variant="contained"
        color="primary"
        type="button"
        sx={{
          maxHeight: (theme) => theme.spacing(4.5),
          paddingLeft: (theme) => theme.spacing(2),
          paddingRight: (theme) => theme.spacing(2),
        }}
      >
        Download
      </Button>
    </Link>
  </Box>
);

export { StickyBanner };
