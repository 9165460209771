export const seoLinksObject = {
  headline: 'Weitere Kreditarten von smava',
  linkBox1: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value:
              'Haben Sie auf dieser Seite nicht das gefunden, was Sie gesucht haben? Dann könnte Sie das interessieren:',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  linkBox2: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value:
              'Nutzen Sie unsere Verwendungszwecke für besonders günstige Konditionen\n\n',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/ratenkredit/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Ratenkredit',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/modernisierungskredit/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Modernisierungskredit',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/autokredit/ballonfinanzierung/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Ballonfinanzierung',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/minikredit/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Minikredit',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  linkBox3: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: 'Mit dem Kreditrechner günstige Kredite ermitteln\n\n',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/guenstiger/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Günstiger Kredit',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/finanzierungsrechner/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Finanzierungsrechner',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/zinsrechner/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Zinsrechner',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/umschuldung/darlehensrechner/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Darlehensrechner',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/zinsvergleich/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Zinsvergleich',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  linkBox4: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: 'smava - Ihr Vergleichsportal für Kredite\n\n',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Online-Kredit',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/konsumentenkredit/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Konsumentenkredit',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  linkBox5: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value:
              'Kleine und große Kredite – Top-Angebote für jeden Anlass\n\n',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/mikrokredit/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Mikrokredit',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/beamtendarlehen/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Beamtenkredit',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  linkBox6: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value:
              'Kredit2Day: Deutschlands erster Kredit mit Sofort-Auszahlung\n\n',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/finanzierung/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Finanzierung',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
          {
            data: {
              uri: 'https://www.smava.de/kredit/expresskredit/',
            },
            content: [
              {
                data: {},
                marks: [],
                value: 'Expresskredit',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
};
